import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SiteSeoPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" title="SEO Оптимізація Вашого Сайту Під Пошукові Системи(0$)"
         title2="SEO оптимізація" image="seo.webp" alt="seo optimization"
          desc="Кожен сайт потребує SEO оптимізації. Ми її виконуємо. В основному,
          вона полягає у підключенні метатегів та додаванню ключових слів у назву, опис та контент сайту.
          Робиться це для того, щоб підвищити кількість відвідувань на сайті та його позицію у пошукових системах відносно конкурентів."></PageItem>
    )
}

export default SiteSeoPage;