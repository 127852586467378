import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const SiteDevPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    const router = useNavigate();

    return(
        <div className="page">
            <Helmet>
                <meta name="description" content="Повна розробка сайтів під ключ. Гарантуємо якість та професійність. Зв'яжіться з нами за номером +38(097)-939-3942"/>
                <title>Розробка Індивідуального Сайту Під Ключ(Від 300$)</title>
            </Helmet>
            <p className="page__title hidden animation1">Розробка</p>
            <img className="page__img hidden animation1" src="images/dev.webp" alt="development" />
            <div className="page-top">
                <p className="page-top__p hidden animation2">Опис</p>
            </div>
            <p className="page__descrp break cancel hidden animation2">
            Ми з вами спілкуємося, обговорюємо сам сайт та його функціонал.
            Після затвердження бюджету та самого сайту, ми починаємо роботу. Для початку ви вирішуєте, який тип дизайну вам найбільше підходить,
            в основному, це шаблонний дизайн або дизайнерський. Шаблонний дизайн - це дизайн, зроблений по вже готовому шаблону, його особливості:<br/>
            -Він дешевший<br/>
            -З ним швидше почати роботу.<br/>
            Дизайн від дизайнера - це індивідуальний дизайн, розроблений спеціально для вас, його особливості:<br/>
            -Більш цікавий та індивідуальний стиль<br/>
            -З ним до часу розробки додається від 4 днів.<br/>
            Коли ви обрали тип дизайну,  переходимо до створення макету(Макет-це зовнішній вигляд майбутнього сайту.).
            Після затвердження вами макету, переходимо до етапу верстки(Верстка-це перенесення макета до html та css.).
            Ми робимо адаптивну верстку, тобто ваш сайт буде гарно виглядати на різних пристроях з різними дисплеями.
            Далі ми проводимо фронт-енд та, якщо потребується, бек-енд розробку, та переходимо до фінального етапу розробки.
            Ми додаємо потрібні налаштування, <a className="linkintext" onClick={()=>{router("/seo")}} >SEO оптимізуємо сайт</a>,
            <a className="linkintext" onClick={()=>{router("/analytics")}}> підключаємо аналітику</a>,
            додаємо потрібний функціонал та повністю тестуємо сайт на різних пристроях в різних браузерах для того, щоб покращити досвід
            користувача з сайтом.<a className="linkintext" onClick={()=>{router("/content")}}> Після цього переходимо до наповнення сайту.</a>
            </p>
        </div>
    )
}

export default SiteDevPage;