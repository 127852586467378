import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SitePublishPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" title="Публікація Вашого Сайту На Хостинг Сервіс(0$)"
         title2="Публікація" image="publish.webp" alt="publish"
          desc="Ми з вами обговорюємо, який хостинг сервіс вам найбільше подобається, та обираємо найкращий для вас,
          публікуємо ваш сайт та проводимо потрібні налаштування. Після цього ми вам віддаємо дані від адмін панелі та вчимо нею користуватися.
          Зазвичай, ми використовуємо хостинг-сервіс Hostinger та пропонуємо premium пакет на один рік,
          який містить в собі домен на рік та багато чого іншого (вартість цього пакета включена у вартість сайту)."></PageItem>
    )
}

export default SitePublishPage;