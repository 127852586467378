import React from "react";
import {useNavigate} from "react-router-dom";

const Item2=(props)=>{

    const router = useNavigate();

    return(
        <div className="item item1 hidden">
            <img className="item__image" src={"/images/"+props.image} alt={props.alt} />
            <p className="item__title">{props.title}</p>
            <p className="item__about break cancel">{props.desc}</p>
            <p className="item__more item__more1" onClick={()=>{router(props.route)}}>Дізнатися більше</p>
        </div>
    )
}

export default Item2;