import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const BsCardPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" title="Створення Сайту-Візитки Під Ключ За Доступною Ціною(від 300$)" title2="Сайт-візитка" image="bscw.webp" alt="business card" desc="Сайт-візитка це сайт, який зазвичай складається з 1-3 сторінок. Основна його мета це надати користувачу інформацію про вас,
        про вашу справу, що може значно збільшити попит на ваші послуги. Можна сказати, це як пластикова візитка, тільки онлайн.
        Строк виконання такого сайту до 2-х тижнів."></PageItem>
    )
}

export default BsCardPage;