import React from "react";
import { Helmet } from "react-helmet";

const PageItem=(props)=>{

    return(
        <div className="page">
            <Helmet>
                <meta name="description" content="Повна розробка сайтів під ключ. Гарантуємо якість та професійність. Зв'яжіться з нами за номером +38(097)-939-3942"/>
                <title>{props.title}</title>
            </Helmet>
            <p className="page__title hidden animation1">{props.title2}</p>
            <img className="page__img hidden animation1" src={"images/"+props.image} alt={props.alt} />
            <div className="page-top">
                <p className="page-top__p hidden animation2">Опис</p>
            </div>
            {props.type=="1"?<p className="page__descrp break cancel hidden animation2">
            {props.desc}<br/>
            *Ціна може відрізнятися залежно від кількості сторінок, типу дизайну, хостинг-сервісу та функціонала сайту. Це все обговорюється до початку роботи. 
            </p>:<p className="page__descrp break cancel hidden animation2">
            {props.desc}
            </p>}
        </div>
    )
}

export default PageItem;