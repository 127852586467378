import React, {useEffect, useState} from "react";
import MyModal from "../UI/MyModal/MyModal";
import { Helmet } from "react-helmet";
import Item from "../components/Item";
import Item2 from "../components/Item2";

const Main=()=>{

    const [width, setWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);
    useEffect(()=>{
        window.addEventListener("resize", ()=>{
            setWidth(window.innerWidth);
        }); 
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
    },[])
    //<MyButton>Всі послуги</MyButton>
    return(
        <div className="main">
            <Helmet>
                <meta name="description" content="Повна розробка сайтів під ключ. Гарантуємо якість та професійність. Зв'яжіться з нами за номером +38(097)-939-3942"/>
                <title>Створення Сайту Під Ключ За Доступною Ціною (від 300$)</title>
            </Helmet>
            <MyModal visible={visible} setVisible={setVisible}>{width}</MyModal>
            <div className="main-block1" id="block1">
                <div className="block1-top">
                    <p className="block1-top-p hidden">Види сайтів та ціни</p>
                </div>
                <div className="block1-middle block2-middle">
                    <Item title="Сайт-візитка" desc="З сайтом-візиткою ви можете надати користувачу коротку, лаконічну,
                         інформативну та привабливу візуально інформацію про свою справу."
                         more="Найбільше підходить для приватних підприємців та малого бізнесу."
                         price="300" image="bscw.webp" alt="business-card"
                         route="/business-card"></Item>
                    <Item title="Сайт-портфоліо" desc="З сайтом-портфоліо ви можете продемонструвати користувачу свої роботи,
                         досягнення та навички через легкий у користуванні вебсайт." more="Найбільше підходить для дизайнерів, художників, фотографів та програмістів."
                          price="300" image="portfolio.webp" alt="portfolio" route="/portfolio"></Item>
                    <Item title="Landing" desc="Landing спонукає користувача виконати певну дію.
                         Це може бути покупка товару, замовлення деякої послуги і т.д.." 
                    more="Найбільше підходить для реклами одного товару чи послуги." price="300" image="landing.webp" alt="landing" route="/landing"></Item>
                    <Item title="Ваш сайт" desc="Якщо у вас є бажання створити вебсайт,
                         який не вказано у нашому списку, ви завжди можете обговорити це питання з нами по телефону."
                     more="Найбільше підходить для всіх, хто хоче мати власний цікавий вебсайт." price="450" image="ownsite.webp" alt="custom" route="/custom"></Item>
                    <Item title="Корпоративний сайт" desc="Корпоративний сайт це сайт, який надає користувачам інформацію про компанію,
                         її продукти або послуги, місію та контактну інформацію." more="Найбільше підходить для великих компаній та масштабних бізнесів." price="2000" image="corporate.webp" alt="corporate" route="/corporate"></Item>
                    <Item title="Інтернет-магазин" desc="Інтернет-магазин це сайт, який надає користувачам можливість переглядати,
                        вибирати та купувати товари або послуги онлайн." more="Найбільше підходить для підприємців та людей, які хочуть почати продавати онлайн." price="2500" image="shop.webp" alt="shop" route="/shop"></Item>
                </div>   
            </div>
            <div className="main-block2" id="block2">
                <div className="block1-top">
                    <p className="block1-top-p hidden">Наші послуги</p>
                </div>
                <div className="block1-middle">
                    <Item2 title="Розробка" image="dev.webp" alt="create website"
                     route="/dev" desc="Розробка вебсайту складається з декількох етапів, всі етапи ми з вами обговорюємо та якісно виконуємо."></Item2>
                    <Item2 title="SEO оптимізація" image="seo.webp"
                     alt="website seo" route="/seo" desc="Ми додаємо потрібні метатеги та оптимізуємо ваш сайт під пошукові системи,
                        що покращує відвідуваність вашого сайту."></Item2>
                    <Item2 title="Наповнення контентом" image="content.webp" alt="website content"
                     route="/content" desc="Після обговорення з вами ми наповнюємо сайт контентом, враховуючи ваші побажання та наш досвід."></Item2>
                    <Item2 title="Публікація" image="publish.webp" alt="website publish"
                     route="/publish" desc="Ми публікуємо сайт на хостинг, віддаємо вам ваш акаунт у хостинг сервісі та пояснюємо, як ним користуватися."></Item2>
                    <Item2 title="Аналітика" image="analytic.webp" alt="website analytics"
                     route="/analytics" desc="Ми підключаємо до сайту аналітику, щоб ви могли слідкувати за успішністю сайту."></Item2>
                    <Item2 title="Підтримка" image="support.webp" alt="website support"
                     route="/support" desc="Після того як наша робота закінчилась, ми будемо надавати вам базову підтримку, ми нікуди не зникаємо."></Item2>        
                </div>
                <div className="block1-featuresdiv">
                    <p className="block1-features hidden">Наші переваги</p>
                </div>
                <div className="block1-last">
                    <ul className="block1-last__list">
                        <li className="block1-last__list-item hidden">Доступні ціни.</li>      
                        <li className="block1-last__list-item hidden">Всі послуги входять у вартість сайту.</li>  
                        <li className="block1-last__list-item hidden">Завжди виконуємо свою роботу вчасно.</li>
                        <li className="block1-last__list-item hidden">Можливість обговорити всі деталі розробки сайту.</li>
                    </ul>
                </div>
            </div>
            <div className="main-block3" id="block3">
                <div className="block1-top tel3">
                    <p className="block1-top-p block3-top-p hidden">Зателефонуйте нам:</p>
                </div>
                <div className="block1-top tel2">
                    <a className="block1-top-p block3-top-p tel5 hidden" href="tel: +380979393942">+38(097)939-3942</a>
                </div>
                <div className="block1-top tel2">
                    <p className="block1-top-p block3-top-p tel2 tel4 hidden">(з 10:00 до 22:00)</p>
                </div>
            </div>
        </div>
    )
}

export default Main;