import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const PortfolioPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" title="Створення Сайту-Портфоліо Під Ключ За Доступною Ціною(від 300$)" title2="Сайт-портфоліо" image="portfolio.webp" alt="portfolio"
         desc="Сайт-портфоліо це сайт, мета якого показати ваші роботи та ваші вміння людям. Це можуть бути фотографії, картини, скрипти тощо.
         Строк виконання такого сайту до 2-х тижнів."></PageItem>
    )
}

export default PortfolioPage;