import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SiteContentPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" title="Створення Контенту Для Вашого Сайту(0$)"
         title2="Наповнення контентом" image="content.webp" alt="content"
          desc="Наповнення сайту контентом це частина не менш важлива, ніж розробка.
          Наповнювати сайт треба, розуміючи логіку пошукових систем та підбираючи правильні слова.
          Ви можете наповнити сайт самі або довірити цю справу нам."></PageItem>
    )
}

export default SiteContentPage;