import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const CorporatePage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" title="Створення Корпоративного Сайту Під Ключ За Ціною(від 2000$)"
         title2="Корпоративний сайт" image="corporate.webp" alt="corporate" desc="Мета корпоративного сайту це збільшити впізнаваність своєї компанії та тим самим збільшити кількість постійних клієнтів.
        Корпоративний сайт набагато більший за сайт-візитку та має значно більше можливостей. Також, це великий проєкт,
        в якому є багато функціонала, крім самого магазину, наприклад: смс-розсилка, з метою збільшити кількість покупців,
        система автоматичної оплати, реєстрація та багато чого іншого. Строк виконання зазвичай до 2-х місяців."></PageItem>
    )
}

export default CorporatePage;