import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const LandingPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" title="Створення Landing Сайту Під Ключ За Доступною Ціною(від 300$)" title2="Landing" image="landing.webp"
         alt="landing" desc="Landing це сайт, який складається зазвичай з 1-3 сторінок. Landing це реклама одного товару чи послуги, з метою збільшити
         попит на цей товар/послугу. Строк виконання такого сайту до 1 місяця."></PageItem>
    )
}

export default LandingPage;