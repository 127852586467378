import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const ShopPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" title="Створення Інтернет Магазину Під Ключ За Ціною(від 2500$)" title2="Інтернет магазин" image="shop.webp" alt="shop"
         desc="Мета інтернет-магазину це продаж товарів онлайн, ми створюємо якісні магазини з системами захисту та сучасним дизайном.
         Також, це великий проєкт, в якому є багато функціонала крім самого магазину,
         наприклад: смс-розсилка з метою збільшити кількість покупців, система автоматичної оплати,
         реєстрація та багато чого іншого. Строк виконання зазвичай до 2-х місяців."></PageItem>
    )
}

export default ShopPage;