import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SiteAnalyticsPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" title="Підключення Аналітики До Вашого Сайту(0$)"
         title2="Аналітика" image="analytic.webp" alt="analytics"
          desc="Хостинг-сервіс, який ми пропонуємо (Hostinger),
          автоматично підключає до сайту аналітику, за допомогою якої ви можете дивитися,
          скільки було запитів на ваш сайт у певний час, але, якщо ви хочете отримувати більше інформації, ми також підключаємо
          Google Analytics."></PageItem>
    )
}

export default SiteAnalyticsPage;