import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const YourSite=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" title="Створення Вашого Сайту Під Ключ За Доступною Ціною(від 450$)"
         title2="Ваш сайт" image="ownsite.webp" alt="your site"
          desc=" Якщо у вас є цікаві пропозиції по створенню сайтів, ви завжди можете звернутися до нас по телефону.
        Це може бути форум, блог тощо. Строк виконання обговорюється після визначення типу та складності сайту."></PageItem>
    )
}

export default YourSite;