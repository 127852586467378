import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SiteSuppPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" title="Наша Підтримка Вашого Сайту Після Закінчення Роботи(0$)"
         title2="Підтримка" image="support.webp" alt="support"
          desc="Надаємо підтримку як під час роботи, так і після завершення.
          Ми відповідаємо на всі ваші питання та пояснюємо як, що, навіщо. Коли робота закінчена, ви завжди можете звертатися до нас з питаннями,
          які вас хвилюють або з пропозиціями до нових проєктів чи вдосконалень, ми обов'язково вас вислухаємо та дамо відповіді на ваші запитання."></PageItem>
    )
}

export default SiteSuppPage;